<template>
  <div class="Products">
    <v-container>
      <v-row class="py-10">
        <v-col cols="6" sm="3" xl="2" class="text-center"  v-for="(product,p) in products" :key="'producto-'+p">
          <router-link :to="'/product/'+product.url">
            <v-img :src="'https://www.lakewaycms.actstudio.xyz/'+product.cover.path" style="border-radius: 50%;" aspect-ratio="1"></v-img>
          </router-link>
          <router-link class="markazi product-title-size black--text" :to="'/product/'+product.url">{{product.name}}</router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      products: [],
      loaded: false
    }
  },
  beforeMount: function () {
    fetch('https://www.lakewaycms.actstudio.xyz/api/collections/get/Products', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            filter: {},
            populate: 1, // resolve linked collection items
        })
    })
    .then(res=>res.json())
    .then(res => this.products = res.entries)
    .then(this.loaded = true);
  }
};
</script>